<template>
    <div class="footer">
        <div>
            <v-row class="description-text justify-center">
                <!-- <v-col
                  sm="4"
                  class="text-center column">
                    <div class="custom-header-left">
                      <h1>Unterstütze uns</h1>
                      <v-divider></v-divider>
                      <a href="https://www.betterplace.me/wg-mal-anders">
                        <v-img
                          :src="require('../assets/heart.png')"
                          :max-width="50"
                          rounded
                          class="icon"
                        ></v-img>
                      </a>
                    </div>
                </v-col> -->
                
                <v-col
                  sm="4"
                  class="text-center column">
                        <div>
                            <h1>Impressum</h1>
                            <v-divider></v-divider>
                        </div>
                       
                   
                        <h3 class="kontakt">Kontakt:</h3>
                    
                 
                        <h3>Melissa Otto &</h3>
                    
                    
                        <h3>Anna Zimmerman</h3>

                        <h3 class="kontakt">04703 Leisnig</h3>

                        <h3>Tel.:</h3>
                        <h3>0152 57615039/</h3>
                        <h3>0172 9374601</h3>

                        <div class="kontakt">
                          <h3>Email:</h3>
                          <h3>anna.zimmermann@liontaria.info</h3>
                        </div>
                        
                   
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  sm="4"
  
                  class="text-center column justify-center d-flex">
                    <div class="custom-header-right">
                        <h1>Folge Uns</h1>
                        <v-divider></v-divider>
                        <a href="https://www.instagram.com/liontaria_official/">
                          <v-img
                            :src="require('../assets/instagram.png')"
                            :max-width="50"
                            rounded
                            class="icon"
                          ></v-img>
                        </a>
                    </div>
                </v-col>
            </v-row>
          </div>
    </div>
</template>
  
<script>

export default {
  name: 'FooterComponent',
  data: () => ({
  }),
  computed: {
    img() {
      return require('@/assets/' + this.msg + '');
    },
  },
};
</script>

  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer{
    /* Set a specific height */
    height: 100%;
    margin-top: 40px;
    padding-top: 40px;
    background-color: rgb(234, 228, 220);
    position: relative;
    overflow: hidden;
    object-fit: cover;
    padding-bottom: 10px;
    font-size: x-small;
    color: rgb(101, 15, 45);

}

/* Place text in the middle of the image*/

.kontakt{
    padding-bottom: 20px;
    padding-top: 20px;
}
.description-text{
  padding-left: 10%;
  padding-right: 10%;
}
.icon{
  margin: auto;
  margin-top: 30px;
}
.column{
  padding-right: 10%;
  padding-left: 10%
}

/*
.h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  } */
</style>
