<template>
<div class="tabs-container">
      <ul>
        <li><router-link to="/"><v-tab class="subtitle">Home</v-tab></router-link></li>
        <li><router-link to="/"><v-tab class="subtitle">Leistungen</v-tab></router-link></li>
        <li><router-link to="/ourProjects"><v-tab class="subtitle">Projekte</v-tab></router-link></li>
        <li><router-link to="/about"><v-tab class="subtitle">Über uns</v-tab></router-link></li>
        <!-- <li><router-link to="/project"><v-tab class="subtitle">Projektumsetzung</v-tab></router-link></li> -->
      </ul>
    </div>
  </template>

<script>
export default {
};
</script>

<style scoped>

ul {
  list-style-type: none;
  margin: auto;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  width:fit-content;
}

li {
  float: left;
  border-bottom: 1px solid #f4deab;
 
  margin-right: 6px;
}

li:last-child {
  border-right: none;
}

li a {
  display: block;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 90%;;
}

li a:hover {
  background-color: #f4deab;
}

.tabs-container{
  font-size:larger;
}
.subtitle{
  font-size: x-large;
}

</style>
